import React from 'react';
import Layout from "../components/layout";
import Component from "../components/Component";
import Block from "../components/Block";
const NotFoundPage = () => (
<Layout>
  <div className="page-content">
    <Block>
      <Component>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Component>
    </Block>
  </div>
</Layout>
)

export default NotFoundPage
